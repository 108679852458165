<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :disabled="loading"
    :loading="loading"
    :filter="filterItems"
    :items="items"
    item-text="name"
    item-value="id"
    @change="select"
    return-object
    :search-input.sync="search"
  >
    <template v-slot:selection="data">
      <PersonName :value="data.item" ext />
    </template>
    <template v-slot:item="data">
      <v-list-item-avatar>
        <PortraitImage :value="data.item" :key="data.item.id" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <PersonName :value="data.item" />
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ personCode(data.item) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import { personCode, searchPerson } from "common/utils/people";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
export default {
  name: "PersonInput",
  props: ["value", "group"],
  components: { PersonName, PortraitImage },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
    };
  },
  watch: {
    async value() {
      if (this.value && this.value.id) {
        if (!this.items.some((person) => person.id === this.value.id)) {
          this.items.push(this.value);
        }
      }
    },
    group() {
      this.getData();
    },
    search() {
      if (this.group) {
        return;
      }
      this.getData(); // search online if no group
    },
  },
  methods: {
    personCode,
    async getData() {
      if (this.group) {
        this.loading = true;
        this.items = await this.apiList({
          resource: "person/person",
          query: "group=" + this.group,
        });
        this.loading = false;
      } else if (this.search && this.search.length >= 3) {
        this.loading = true;
        const results = await this.apiList({
          resource: "person/person",
          query: "search=" + this.search,
        });
        this.items = results.flatMap((el) => el.items);
        this.loading = false;
      }
    },
    filterItems: (item, queryText) => searchPerson(item, queryText),
    remove() {
      this.$emit("input", null);
    },
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    this.getData();
  },
};
</script>
