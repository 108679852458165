<template>
  <div>
    <v-list v-if="$vuetify.breakpoint.xs">
      <v-list-item-group v-model="selected" multiple>
        <v-list-item v-for="item in items" :key="item.id" :value="item">
          <template v-slot:default="{ active }">
            <v-list-item-action v-if="isTeacher && actions">
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-avatar v-if="!hideStudent"
              ><PortraitImage :value="item.student"></PortraitImage
            ></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><PersonName
                  :value="item.student"
                  v-if="!hideStudent"
                ></PersonName>
                {{ item.description }} ({{ item.teacher.code }})
              </v-list-item-title>

              <v-list-item-subtitle>
                <v-badge v-if="tooOld(item)" color="danger" dot inline>
                  <DateValue :value="item.date" long />
                  , {{ item.startTime }}
                </v-badge>
                <template v-else>
                  <DateValue :value="item.date" long />,
                  {{ item.startTime }}
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-data-table
      v-else
      v-model="selected"
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="itemsSorted"
      item-key="id"
      disable-sort
      disable-filtering
      :show-select="isTeacher && actions && actions.length > 0"
    >
      <template v-slot:item.student="{ item }">
        <PersonItem :value="item.student" small />
      </template>
      <template v-slot:item.date="{ item }">
        <v-badge v-if="tooOld(item)" color="danger" dot inline>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <DateValue v-bind="attrs" v-on="on" :value="item.date" long />
            </template>
            <span>{{
              age(item.date) == 0 ? "heute" : `vor ${age(item.date)} Tagen`
            }}</span>
          </v-tooltip>
        </v-badge>

        <DateValue v-else :value="item.date" long />
      </template>
    </v-data-table>
    <template v-if="isTeacher && actions">
      <v-divider></v-divider>
      <v-toolbar flat dense>
        {{ selected.length }} markierte Absenz(en)
        <v-spacer />
        <v-btn
          v-if="isTeacher && actions.includes('delete')"
          text
          color="danger"
          @click="deleteSelected"
          :loading="deleting"
          :disabled="unexcusing || reactivating || selected.length < 1"
          >Löschen</v-btn
        >
        <v-btn
          v-if="isTeacher && actions.includes('unexcuse')"
          text
          color="error"
          @click="unexcusedSelected"
          :loading="unexcusing"
          :disabled="deleting || reactivating || selected.length < 1"
          >Unentschuldigt</v-btn
        >
        <v-btn
          v-if="isTeacher && actions.includes('reactivate')"
          text
          color="info"
          @click="reactivateSelected"
          :loading="reactivating"
          :disabled="unexcusing || deleting || selected.length < 1"
          >reaktivieren</v-btn
        >
      </v-toolbar>
    </template>
  </div>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

import { age } from "common/utils/date.js";

export default {
  components: {
    DateValue,
    PersonItem,
    PersonName,
    PortraitImage,
  },
  props: {
    actions: [], // ["delete", "reactivate", "unexcuse"]
    items: [],
    hideStudent: { type: Boolean, default: false },
  },
  data() {
    return {
      deleting: false,
      selected: [],
      unexcusing: false,
      reactivating: false,
      headers: this.hideStudent
        ? [
            {
              text: "Tag",
              value: "date",
            },
            {
              text: "Lektion",
              value: "startTime",
            },
            {
              text: "Beschreibung",
              value: "description",
            },
            {
              text: "Status",
              value: "status.description",
            },
            {
              text: "LK",
              value: "teacher.code",
            },
          ]
        : [
            {
              text: "",
              value: "student",
            },
            {
              text: "Tag",
              value: "date",
            },
            {
              text: "Lektion",
              value: "startTime",
            },
            {
              text: "Beschreibung",
              value: "description",
            },
            {
              text: "Status",
              value: "status.description",
            },
            {
              text: "LK",
              value: "teacher.code",
            },
          ],
    };
  },
  computed: {
    itemsSorted() {
      return [...this.items].sort((a, b) => {
        const aValue = a.date + a.startTime;
        const bValue = b.date + b.endTime;
        return aValue.localeCompare(bValue);
      });
    },
    isTeacher() {
      return this.$_hasRole("teacher");
    },
  },
  methods: {
    age,
    isToday(item) {
      return this.age(item.date) == 0;
    },
    tooOld(item) {
      return item.status.code == "pending" && this.age(item.date) > 8;
    },

    async deleteSelected() {
      if (
        await this.$root.confirm({
          message: `Sollen die markierten Absenzen gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.deleting = true;
        console.log(this.selected);
        for (const absence of this.selected) {
          await this.apiDelete({
            resource: "absence/absence",
            id: absence.id,
          });
        }
        this.selected = [];
        this.$emit("updated");
        this.deleting = false;
      }
    },
    async reactivateSelected() {
      if (
        await this.$root.confirm({
          message: `Sollen die markierten Absenzen wieder als pendent gesetzt werden?`,
          color: "info",
          icon: "mdi-information",
        })
      ) {
        this.reactivating = true;
        for (const absence of this.selected) {
          await this.apiPatch({
            resource: "absence/absence",
            id: absence.id,
            key: "status",
            value: "pending",
          });
        }
        this.selected = [];
        this.$emit("updated");
        this.reactivating = false;
      }
    },

    async unexcusedSelected() {
      if (
        await this.$root.confirm({
          message: `Sollen die markierten Absenzen als Unentschuldigte eingetragen werden?`,
          color: "error",
          icon: "mdi-alert",
        })
      ) {
        this.unexcusing = true;
        for (const absence of this.selected) {
          await this.apiPatch({
            resource: "absence/absence",
            id: absence.id,
            key: "status",
            value: "unexcused",
          });
        }
        this.selected = [];
        this.$emit("updated");
        this.unexcusing = false;
      }
    },
  },
  created() {},
};
</script>
